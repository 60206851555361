@use '@angular/material' as mat;

@mixin x-shop-pill-theme($theme-config) {
  $theme: map-get($theme-config, theme);

  .x-shop-pill {
    background: mat.get-color-from-palette(map-get($theme, accent), 300);
    color: mat.get-color-from-palette(map-get($theme, accent), 300-contrast);
    padding: 5px 8px;
    font-weight: 400;
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    overflow: hidden;

    &.success {
      background: get-custom-color($theme-config, success);
      color: darken(get-custom-color($theme-config, success), 70%);
    }

    &.warn {
      background: #fcd8af;
      color: #2f1a00;
    }

    &.error {
      background: #ffe0d9;
      color: #630e0f;
    }
  }
}
