@mixin x-delivery-conflict-dialog-theme($theme-config) {
  .x-delivery-conflict-dialog {
    .items {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .item {
        display: flex;
        gap: 10px;
        align-items: center;

        .item-image {
          width: 80px;
          flex-shrink: 0;
        }

        .item-details {
          .item-state {
            color: #818181;

            display: flex;
            align-items: center;
            gap: 10px;
          }
        }
      }
    }

    .actions {
      @include media-breakpoint-down(lg) {
        flex-direction: column;
      }
    }
  }
}
